.minter-alarm {
  //margin-top: 20px;
  float: left;
}
.minter-alarm  .ant-statistic-title,.minter-alarm span{
  color: white;
}
.minter-alarm  .ant-statistic-content {
  font-size: 16px;
}
.minter-alarm .ant-form-item-control-input-content {
  //max-width: 35%
}
