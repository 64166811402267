@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.ant-spinner {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
