.profile-info {
  //width: 50%;
  text-align: left;
  word-break: break-all;
  padding-left: 8px;
}
.profile-info .readonly {
  //margin-left: 10%;
  padding-left: 10%
}
.profile-info .readonly-row {
  //margin-bottom: 10px;
}
