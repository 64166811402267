.personal-form-wrap .ant-input-disabled,
.personal-form-wrap .ant-picker-input input,
.personal-form-wrap .ant-select-selection-item {
  color: #000000;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.personal-form-wrap {}
