.row-right-aligned {
    position: relative;
}
.right-button {
    position: absolute;
    right: 10px;
    top: -10px;
}
/*.ant-form-item-label {*/
  /*min-width: 100%;*/
/*}*/
